import configs from '@configs'
import { format, SHA256 } from 'crypto-js'
import dayjs from 'dayjs'
import { Bills } from 'gadjet-v2-types/dist/model'
import qs from 'qs'
import { useMemo, useRef, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const isDev = process.env.NODE_ENV === 'development'

export default function MobilePay(): JSX.Element {
  const { push } = useHistory()
  const { state } = useLocation<{ bill?: Bills; mid: string; merchantKey: string }>()
  const { bill, mid, merchantKey } = state || {}

  const actionUrl = useMemo(() => {
    if (!bill) return ''
    const resultUrl = `${window.location.protocol}//${window.location.host}/bills/${bill.billId}/pay/result`
    const apiHost = isDev ? configs.API_HOST_DEV : configs.API_HOST
    return `${apiHost}/interlock/nicepay?resultUrl=${resultUrl}`
  }, [bill])

  const form = useRef<HTMLFormElement>(null)

  const item = useMemo(() => {
    if (!bill) return ''
    const { spaces, additions, surcharges } = bill
    const items = [...spaces, ...additions, ...surcharges]
    const names = items.map(({ name }) => name)

    if (names.length === 0) return '상품없음'
    if (names.length === 1) return names[0]
    if (names.length > 1) return `${names[0]} 외 ${names.length - 1}건`
  }, [bill])
  const price = useMemo(() => Number(bill?.price || 0), [bill])

  const isDeposit = useMemo(() => bill?.type === 'deposit', [bill])
  const payMethod = useMemo(() => (isDeposit ? 'BANK,VBANK' : 'CARD,BANK,VBANK'), [isDeposit])

  const pay = () => {
    if (!bill) return
    if (!form?.current) return

    const date = dayjs().format('YYYYMMDDHHmmss')
    const moid = Date.now()
    const signData = SHA256(`${date}${mid}${price}${merchantKey}`, { format: format.Hex }).toString()

    const formData = {
      Moid: moid,
      GoodsName: item,
      Amt: price,
      MID: mid,
      EdiDate: date,
      SignData: signData,
      ReturnURL: actionUrl,
      ReqReserved: qs.stringify({ type: 'bill-payment', billId: bill.billId, branchId: bill.branchId }),
      PayMethod: payMethod,
      CharSet: 'utf-8',
      WapUrl: 'gadjet://',
      IspCancelUrl: 'gadjet://',
    }

    Object.entries(formData).forEach(([key, value]) => {
      const input = document.createElement('input')
      input.setAttribute('name', key)
      input.setAttribute('value', String(value))
      input.setAttribute('type', 'hidden')
      form.current?.appendChild(input)
    })
    form.current.submit()
  }

  useEffect(() => {
    const isDeny = !bill || !mid || !merchantKey
    if (isDeny) return push('/error')
    pay()
  }, [bill, mid, merchantKey])

  return (
    <form ref={form} action="https://web.nicepay.co.kr/v3/v3Payment.jsp" method="post" acceptCharset="EUC-KR"></form>
  )
}
