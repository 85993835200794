const configs = {
  API_HOST: 'https://api-dot-gadjet-v2.du.r.appspot.com/api/v2',
  API_HOST_DEV: 'http://192.168.0.27:8085/api/v2',
  RETURN_URL_HOST: 'https://api.gadjet.io',
  RETURN_URL_HOST_DEV: 'http://192.168.0.27:8085/api/v2',
  NICE_PAY_KEY: {
    GENERAL: {
      // 국내인증
      mid: 'gadjets03m',
      merchantKey: '0LrejoaNjAE1Xkv+Q00jPKNzr11ND3ToEPUDRFD+dMeZ4tTy0mFWHboSlexdktC4O5/ukTJGr/HN9y+9342tRg==',
    },
    OVERSEA: {
      // 해외카드
      mid: 'gadjets02m',
      merchantKey: '4qsiPXdCnDQPEdXhV2OAXODq44mdCbauR8jdZMhw75kLSGJBlbvj+TyQWmgNslc8SWBRKSXskjAZ2kbN7mlYbA==',
    },
    BILLING: {
      // 비인증(자동결제)
      mid: 'gadjets01m',
      merchantKey: 'ITKZqLn6/dz4hkW5U7WzwuP7OJrOCXZ7WvM+4eS9yxaztgiMreEll9Lt8A615R8RTMgKWDsXnlOkdGQaYzQiFA==',
    },
  },
}

export default configs
