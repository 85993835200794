import { Table, TableColumnType } from 'antd'
import { Branches } from 'gadjet-v2-types/dist/model'
import { CSSProperties, useMemo } from 'react'

import Spacer from '@components/atoms/Spacer'

type Props = {
  billId: number
  branch: Branches
}

export default function PayFooter({ billId, branch }: Props): JSX.Element {
  const data = useMemo(
    () => ({
      item: [
        { label: '서비스제공 사업자', value: branch.business?.name },
        { label: '상품번호', value: billId },
        { label: '취소/중도해약/해지조건 및 환불기준', value: '서비스제공 사업자 약관에 따름' },
        { label: '취소/환불 방법', value: '서비스제공 사업자 운영지침에 따름' },
        { label: '문의전화', value: branch.contact },
      ],
      seller: [
        { label: '사업자', value: branch.business?.name },
        { label: '대표자', value: branch.business?.director },
        { label: '사업자등록번호', value: branch.business?.businessNumber },
        { label: '소재지', value: branch.business?.address },
        { label: '연락처', value: branch.contact },
        { label: '이메일', value: branch.email },
      ],
    }),
    [billId, branch]
  )
  const columns: TableColumnType<{ label: string; value: string | number | undefined }>[] = [
    { key: 'label', dataIndex: 'label', render: (label) => <span style={style.labelText}>{label}</span>, width: '33%' },
    { key: 'value', dataIndex: 'value', render: (value) => <span style={style.valueText}>{value}</span>, width: '65%' },
  ]
  return (
    <div>
      <Table
        title={() => '상품정보'}
        dataSource={data.item}
        columns={columns}
        showHeader={false}
        size="small"
        pagination={false}
        rowKey={(value) => `${JSON.stringify(value)}`}
      />
      <Spacer />

      <Table
        title={() => '판매자정보'}
        dataSource={data.seller}
        columns={columns}
        showHeader={false}
        size="small"
        pagination={false}
        rowKey={(value) => `${JSON.stringify(value)}`}
      />
      <Spacer />

      <div style={style.footer}>
        <p style={style.footerText}>
          주식회사지랩스 / 제2018-서울강남-02569호 / 백기민 / 378-87-00388 / 07040254205 / support@gadjet.io /
          서울특별시 강남구 선릉로 578, 7층(삼성동)
        </p>
        <p style={style.footerText}>
          주식회사 지랩스는 결제정보의 중개서비스 또는 통신판매중개시스템의 제공자로서, 통신판매의 당사자가 아니며
          상품의 이용, 환불 등과 관련한 의무와 책임은 각 판매자에게 있습니다.
        </p>
        <p style={style.footerText}>Copyright © 2021-g-labs Inc. All Rights Reserved.</p>
      </div>
      <Spacer />
    </div>
  )
}

const style: { [key: string]: CSSProperties } = {
  labelText: { fontSize: 12 },
  valueText: { fontSize: 14 },
  footer: { textAlign: 'center' },
  footerText: { fontSize: 12, color: 'gray' },
}
