import { Result } from 'antd'
import dayjs from 'dayjs'
import { ApprovePayResponse } from 'gadjet-v2-types/dist/type'
import qs from 'qs'
import { CSSProperties, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import krw from '@utils/krw'

export default function PayResult(): JSX.Element {
  const { search = '?' } = useLocation()

  const approvePayResponse = useMemo(() => qs.parse(search.slice(1)) as unknown as ApprovePayResponse, [search])

  useEffect(() => {
    const _w = window as any
    if (_w.ReactNativeWebView?.postMessage) _w.ReactNativeWebView?.postMessage(search.slice(1))
  }, [])

  if (approvePayResponse.result === 'fail') {
    return <Result status="error" title="결제가 실패했습니다." subTitle={approvePayResponse.message} />
  }
  if (approvePayResponse.type === 'direct') {
    const date = dayjs(approvePayResponse.data?.expireDatetime).format('YYYY년 M월 D일')
    return (
      <Result
        style={style.result}
        status="info"
        title={
          <div style={style.vBankContainer}>
            <div style={style.vBankWrapper}>
              <div style={style.vBankLabel}>은행</div>
              <div style={style.vBankValue}>{approvePayResponse.data?.bankName}</div>
            </div>
            <div style={style.vBankWrapper}>
              <div style={style.vBankLabel}>예금주</div>
              <div style={style.vBankValue}>주식회사 지랩스</div>
            </div>
            <div style={style.vBankWrapper}>
              <div style={style.vBankLabel}>계좌번호</div>
              <div style={style.vBankValue}>{approvePayResponse.data?.account}</div>
            </div>
            <div style={style.vBankWrapper}>
              <div style={style.vBankLabel}>금액</div>
              <div style={style.vBankValue}>{krw(approvePayResponse.data?.price || 0)}</div>
            </div>
          </div>
        }
        subTitle={
          <span>
            입금 마감일은 <b>{date}</b> 입니다.
          </span>
        }
      />
    )
  }

  return (
    <Result
      style={style.result}
      status="success"
      title="결제가 완료되었습니다."
      subTitle={approvePayResponse.message}
    />
  )
}

const style: { [key: string]: CSSProperties } = {
  result: { maxWidth: '100%', paddingLeft: 0, paddingRight: 0 },
  vBankContainer: { maxWidth: '100%', width: '400px', textAlign: 'left', margin: 'auto' },
  vBankWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '20px' },
  vBankLabel: { fontSize: 18, opacity: 0.6, width: '100px' },
  vBankValue: { fontSize: 24, fontWeight: 'bold' },
}
