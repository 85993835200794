import { BrowserRouter, Route, Switch } from 'react-router-dom'

import BillPay from '@pages/Bill/Pay'
import DesktopPay from '@pages/Bill/Pay/DesktopPay'
import MobilePay from '@pages/Bill/Pay/MobilePay'
import PayResult from '@pages/Bill/Pay/Result'
import Error from '@pages/Status/Error'

export default function Routes(): JSX.Element {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/bills/:billId/pay/mobile" exact>
          <MobilePay />
        </Route>
        <Route path="/bills/:billId/pay/desktop" exact>
          <DesktopPay />
        </Route>
        <Route path="/bills/:billId/pay/result" exact>
          <PayResult />
        </Route>
        <Route path="/bills/:billId/pay" exact>
          <BillPay />
        </Route>
        <Route path="*">
          <Error />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
