import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

type Props = { loading?: boolean; children: React.ReactNode }

export default function Loading({ loading = true, children }: Props): JSX.Element {
  return (
    <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />}>
      {children}
    </Spin>
  )
}
