import configs from '@configs'
import { Button, Radio, RadioChangeEvent, Space, Typography } from 'antd'
import { Bills } from 'gadjet-v2-types/dist/model'
import { PayType } from 'gadjet-v2-types/dist/type'
import { useEffect, useMemo, useState } from 'react'
import { isMobile, isDesktop } from 'react-device-detect'
import { useHistory, useParams } from 'react-router-dom'

import krw from '@utils/krw'

import BillAPI from '@apis/bill'

import Loading from '@components/atoms/Loading'
import Spacer from '@components/atoms/Spacer'
import PayFooter from '@components/organisms/Pay/PayFooter'
import PayItemTable from '@components/organisms/Pay/PayItemTable'

export default function BillPay(): JSX.Element {
  const { billId } = useParams<{ billId: string }>()
  const { push } = useHistory()

  const [loading, setLoading] = useState(false)
  const [bill, setBill] = useState<Bills>()

  const [payType, setPayType] = useState<PayType>('GENERAL')

  const isPayable = useMemo(() => !!bill && bill.payments?.length === 0, [bill])
  const { mid, merchantKey } = useMemo(() => configs.NICE_PAY_KEY[payType], [payType])

  const branchName = useMemo(() => {
    if (!bill?.branch) return
    const { branch } = bill
    return `${branch.hq?.name} ${branch.name}`
  }, [bill])
  const items = useMemo(() => {
    if (!bill) return []
    return [...bill.spaces, ...bill.additions, ...bill.surcharges]
  }, [bill])

  const onChangePayType = (e: RadioChangeEvent) => setPayType(e.target.value)
  const onClickPay = () => {
    if (isMobile) push(`/bills/${billId}/pay/mobile`, { bill, mid, merchantKey })
    if (isDesktop) push(`/bills/${billId}/pay/desktop`, { bill, mid, merchantKey })
  }

  const getBill = async () => {
    const _billId = Number(billId)
    setLoading(true)

    const { data } = await BillAPI.getBill(_billId)
    setBill(data.bill)
    setLoading(false)
  }

  const startPay = async () => {
    await getBill()
  }

  useEffect(() => {
    startPay()
  }, [])

  return (
    <Loading loading={loading}>
      <Typography.Text>
        <h2>{branchName}</h2>
      </Typography.Text>

      <Spacer />

      <PayItemTable items={items} />

      <div
        style={{
          fontSize: 20,
          fontWeight: 'bold',
          textAlign: 'right',
          paddingTop: '10px',
        }}
      >
        {krw(bill?.price || 0)}
      </div>

      <Spacer />

      <Radio.Group onChange={onChangePayType} value={payType} size="large">
        <Space direction="vertical">
          <Radio value={'GENERAL'}>일반결제</Radio>
          <Radio value={'OVERSEA'}>해외카드</Radio>
        </Space>
      </Radio.Group>

      <Spacer />

      {isPayable ? (
        <Button size="large" onClick={onClickPay} type="primary" block>
          결제하기
        </Button>
      ) : (
        <Button size="large" type="primary" block disabled>
          이미 결제 된 청구서 입니다.
        </Button>
      )}

      <Spacer />

      {bill && bill.branch && <PayFooter branch={bill.branch} billId={bill.billId} />}
    </Loading>
  )
}
