import { Table, TableColumnType } from 'antd'
import { BillPrice } from 'gadjet-v2-types/dist/type'

import krw from '@utils/krw'

type Props = {
  items: BillPrice[]
}

export default function PayItemTable({ items }: Props): JSX.Element {
  const columns: TableColumnType<BillPrice>[] = [
    { key: 'name', dataIndex: 'name', title: '이름', width: '30%' },
    { key: 'price', dataIndex: 'price', title: '비용', render: (price) => krw(price), align: 'right' },
    { key: 'vat', dataIndex: 'vat', title: '부가세', render: (vat) => krw(vat), align: 'right', width: '30%' },
  ]

  return (
    <Table<BillPrice>
      rowKey={({ name, price }) => `${name}${price}`}
      columns={columns}
      dataSource={items}
      size="small"
      pagination={false}
    />
  )
}
