import configs from '@configs'
import axios from 'axios'

const isDev = process.env.NODE_ENV === 'development'

const { API_HOST, API_HOST_DEV } = configs

const instance = axios.create({
  baseURL: isDev ? API_HOST_DEV : API_HOST,
})

export default instance
